import React from "react";
import PropTypes from "prop-types";
import { Container, Grid, Box, Divider} from "@material-ui/core";
import "./layout.css"
import { StaticImage } from "gatsby-plugin-image";
import EmailIcon from '@material-ui/icons/Email';
import dayjs from "dayjs";
import { Element } from 'react-scroll';
import { Link } from "gatsby";

export default function Footer(props){  

  return (
    <Element name="footer-section">
      <footer>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              {/* LOGO */}
              <StaticImage src="../img/hscd_logo_v2.png" alt="" className="footerLogo" placeholder="blurred"/>
            </Grid>
            <Grid item xs={12} sm={4} md={2} className="footerBox">
              <h5>
                ABOUT
              </h5>
              <p className="bodyText">
                <Link to="/currenthighsheriff">
                  The High Sheriff
                </Link>
              </p>
              <p className="bodyText">
                <Link to="/history">
                  History
                </Link>
              </p>
              <p className="bodyText">
                <Link to="/pasthighsheriffs">
                  Past High Sheriffs
                </Link>
              </p>
              <p className="bodyText">
                <Link to="/countydown">
                  Co Down
                </Link>
              </p>
              <p className="bodyText">
                <Link to="/news">
                  News
                </Link>
              </p>
            </Grid>
            <Grid item xs={12} sm={8} md={4} className="footerBox">
              <h5>
                USEFUL LINKS
              </h5>
              <p className="bodyText">
                <a href="http://www.lordlieutenantofdown.org.uk/" target="_blank" rel="noreferrer">
                  Lord Lieutenant of County Down
                </a>
              </p>
              <p className="bodyText">
                <a href="https://www.lisburncastlereagh.gov.uk" target="_blank" rel="noreferrer">
                  Lisburn &amp; Castlereagh City Council
                </a>
              </p>
              <p className="bodyText">
                <a href="https://www.ardsandnorthdown.gov.uk" target="_blank" rel="noreferrer">
                  Ards and North Down Borough Council
                </a>
              </p>
              <p className="bodyText">
                <a href="https://www.armaghbanbridgecraigavon.gov.uk" target="_blank" rel="noreferrer">
                  Armagh City, Banbridge and Craigavon Borough Council
                </a>
              </p>
              <p className="bodyText">
                <a href="https://www.newrymournedown.org" target="_blank" rel="noreferrer">
                  Newry, Mourne and Down District Council
                </a>
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={3} className="footerBox">
              <h5>
                CONTACT
              </h5>
              <Box style={{display:"flex"}}>
                  <EmailIcon fontSize="small" className="iconSmall" />
                  <p className="bodyText">
                    <a href="mailto:sheriff@thecountyofdown.co.uk">
                      sheriff@thecountyofdown.co.uk
                    </a>
                  </p>
              </Box>
            </Grid>
          </Grid>
          <Divider className="divider" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p className="bodyText sml">
                &copy; {dayjs().year()} High Sheriff of County Down. All Rights Reserved.
              </p>
            </Grid>
          </Grid>
        </Container>
      </footer> 
    </Element>             
  );
};

Footer.propTypes = {
props: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subHeading: PropTypes.string,
      contactEmail: PropTypes.string,
      contactPhone: PropTypes.string,
      facebookLink: PropTypes.string,
      twitterLink: PropTypes.string,
      instagramLink: PropTypes.string,
    })
  ),
};
